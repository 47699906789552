import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"

import { Layout } from "../components/Layout"
import { TeamPage } from "../components/Team/TeamPage"
import { TeamHeader } from "../components/Team/TeamHeader"
import { TeamContent } from "../components/Team/TeamContent"
import { ModalUser } from "../components/Team/ModalUser"

export default function Team({ data }) {
  const [curUser, setCurUser] = useState(null)

  const {
    site: {
      siteMetadata: { title },
    },
    globalMetaData,
    barData,
    headerData,
    footerData,
    teamPage: {
      metaData,
      topHeader,
      teamMembers,
      ctaTitle,
      tickerDuration,
      tickerData,
    },
  } = data

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash
      if (hash)
        setCurUser(teamMembers.find(({ slug }) => slug === hash.slice(1)))
    }

    if (typeof document !== "undefined") document.documentElement.scrollTop = 0
  }, [teamMembers])

  const Modal = () => {
    if (curUser) {
      navigate(`#${curUser.slug}`)
      return (
        <ModalUser
          {...curUser}
          onClose={() => {
            changeUrlCLose()
            setCurUser(null)
          }}
        />
      )
    }
    return null
  }

  const changeUrlCLose = () => {
    if (typeof window !== "undefined") navigate(window.location.pathname)
  }

  return (
    <Layout
      barData={barData}
      headerData={headerData}
      footerData={footerData}
      ctaType="cta-inner"
      ctaTitle={ctaTitle}
      tickerDuration={tickerDuration}
      tickerData={tickerData}
      pageTitle="Team"
      title={title}
      globalMetaData={globalMetaData}
      metaData={metaData}
    >
      <TeamPage>
        <TeamHeader header={topHeader} />
        <TeamContent content={teamMembers} setCurUser={setCurUser} />
        <Modal />
      </TeamPage>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        title
      }
    }
    globalMetaData: contentfulGlobalMetaData {
      desc {
        desc
      }
      keywords {
        keywords
      }
      image {
        file {
          src: url
        }
      }
    }
    barData: contentfulSectionAnnouncementBar {
      visible
      text
      link
    }
    headerData: contentfulSectionHeader {
      logo {
        file {
          src: url
        }
        alt: title
      }
      logoMobile {
        file {
          src: url
        }
        alt: title
      }
    }
    footerData: contentfulSectionFooter {
      email
      secondEmail
      social {
        icon {
          file {
            src: url
          }
          alt: title
        }
        link
      }
      newsletterTitle
      logo {
        file {
          src: url
        }
        alt: title
      }
      copyright
    }
    teamPage: contentfulPageTeam {
      metaData {
        title
        desc {
          desc
        }
        keywords {
          keywords
        }
        image {
          file {
            src: url
          }
        }
      }
      topHeader {
        title
        desc {
          text: desc
        }
      }
      teamMembers {
        slug
        smallPhoto {
          fluid(maxWidth: 294) {
            ...GatsbyContentfulFluid
          }
          alt: title
        }
        position
        name
        largePhoto {
          fluid(maxWidth: 760) {
            ...GatsbyContentfulFluid
          }
          title
        }
        social {
          icon {
            file {
              src: url
            }
            alt: title
          }
          link
        }
        desc {
          text: desc
        }
        faq {
          title
          text {
            text
          }
        }
      }
      ctaTitle
      tickerDuration {
        duration
      }
      tickerData: ticker {
        text {
          text
        }
      }
    }
  }
`
